@tailwind base;
@tailwind components;
@tailwind utilities;


@layer base{
  *{
    font-family: 'Montserrat', sans-serif;
  }

  div.arrow-div {
    width: 6%;
    height: 100%;
    position: relative;
  }
  div.arrow-div:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    border-style: solid;
    transform: translate(-40%, -50%);
    border-width: 0 10px 20px 10px;
    border-color: transparent transparent white transparent;
    
  }

  div.arrow-div-black:after {
    border-color: transparent transparent black transparent;
  }

  div.arrow-div:before {
    content: '';
      position: absolute;
      bottom: -6px;
      left: -7px;
      border-style: solid;
      transform: rotate(60deg);
      border-width: 0 10px 20px 12px;
      border-color: transparent transparent white transparent;
  }

  div.arrow-div-black:before{
    border-color: transparent transparent black transparent;
  }

}
